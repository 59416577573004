.Modal {
    width: 100%;
    height: 100%;
}

.card {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 9999;
    text-align: left;
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 8px, rgba(0, 0, 0, 0.6) 0px 14px 26px -1px, rgba(0, 0, 0, 0.5) 0px -5px 0px inset;
    background-color: #fff;
    padding: 1rem;
    width: 50%;
    /* max-width: 800px; */
    max-height: 80%;
    overflow-y: auto; 
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.card::-webkit-scrollbar {
    display: none;
}

.dismiss {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .9rem 1rem;
    background-color: #fff;
    color: #555;
    border: 2px solid #d1d5db;
    font-size: 1rem;
    font-weight: 300;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    transition: 0.3s ease;
}

.dismiss:hover {
    background-color: #f46464;
    border: 2px solid #f46464;
    color: #fff;
}

.header {
    width: 100%;
}

.bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.content {
    text-align: left;
    width: 100%;
}

.content-center {
    text-align: left;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
}

.title {
    color: #007bff;
    font-size: 2rem;
    font-weight: 700;
    line-height: 3.5rem;
}

.title-span {
    color: #333;
}

.form {
    display: flex;
    flex-direction: column;
    width: 98%;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
}

.form-group input,
.form-group textarea {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    width: 100%;
    align-self: center;
    max-width: 100%;
    border-radius: 0.375rem;
    color: #333;
}

.submit {
    display: inline-flex;
    margin-top: 1rem;
    padding: .5rem 1rem;
    color: #fff;
    background-color: #5C86FF;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    justify-content: center;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card p {
    font-size: 1.3rem;
    color: #4e4e4e;
    font-weight: 600;
    /* max-width: 40vw; */
    text-align: left;
    margin-top: 1.5rem;
}

.submit:hover {
    background-color: #5072db;
}

textarea {
    height: 100px;
    resize: none;
}

.spinner {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #333;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  
/* Add success animation styles */
.success-animation {
    animation: fadeOut .5s linear forwards;
}
  
@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@media (max-width: 1024px) {
    .Modal {
        top: -5%;
        width: 100%;
    }

    .card {
        padding: 1.5rem;
    }

    .header {
        width: 100%;
    }

    .content {
        text-align: left;
        width: 100%;
    }

    .bottom-content {
        width: 100%;
    }

    .card p {
        font-size: 1.3rem;
        margin-top: 1.1rem;
    }

    .bottom-content {
        margin-top: .5rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 1rem;
        /* width: 20em; */
    }
}

@media (max-width: 768px) {
    .Modal {
        top: -15%;
    }

    .card {
        padding: 1rem;
        width: 90%;
        align-self: center;
        justify-content: center;
        align-items: center;
    }

    .header {
        width: 100%;
    }

    .content {
        text-align: left;
        width: 100%;
    }

    .bottom-content {
        width: 100%;
    }

    .card p {
        font-size: 1.3rem;
        margin-top: 1.1rem;
        /* max-width: 500px; */
    }

    .bottom-content {
        margin-top: .5rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 1rem;
        width: 20em;
        max-width: 70%;
        min-width: 20em;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .Modal {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .card {
        background-color: #1f1f1f;
        box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 8px, rgba(0, 0, 0, 0.6) 0px 14px 26px -1px, rgba(0, 0, 0, 0.5) 0px -5px 0px inset;
    }

    .dismiss {
        background-color: #222;
        color: #ccc;
        border: 1px solid #aaa;
    }

    .dismiss:hover {
        background-color: #f46464;
        border: 2px solid #f46464;
        color: #fff;
    }

    .title {
        color: #fff;
    }

    .title-span {
        color: #5C86FF;
    }

    .form-group input,
    .form-group textarea {
        border: .5px solid #ccc;
        color: #fff;
        background-color: #1f1f1f;
    }

    .submit {
        color: #fff;
        background-color: #5C86FF;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    }

    .submit:hover {
        background-color: #5072db;
    }

    .card p {
        color: #fff;
    }

    .spinner {
        border: 2px solid #f3f3f3;
        border-radius: 50%;
        border-top: 2px solid #fff;
    }
}

