.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 100;
}

.footer-link {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.footer-link:hover {
    /* color: #5C86FF; */
    color: #FBC56C;
    transform: scale(1.03);
}

.lower-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: -1;
}
  
.Footer a {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    text-decoration: underline;
    cursor: pointer;
}

.Footer a:hover {
    /* color: #5C86FF; */
    color: #FBC56C;
}

.Footer p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
}

@media (max-width: 1200px) {
    .Footer {
        /* flex-direction: column; */
    }

    .Footer {
        gap: 0.5rem;
    }

    .Footer a {
        font-size: 1rem;
    }

    .Footer p {
        font-size: 1rem;
    }

    .mobile-hidden {
        /* display: none; */
    }
}

@media screen and (prefers-color-scheme: dark) {
    .footer-link {
        color: #eee;
    }

    .footer-link:hover {
        color: #FBC56C;
        transform: scale(1.03);
    }

    .Footer a {
        color: #eee;
    }

    .Footer p {
        color: #eee;
    }
}