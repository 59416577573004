.Summary {
    position: relative;
    padding: 1rem 1rem;
    border: 1px solid #bbb;
    border-radius: 10px;
    border: 1px solid #aaa;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    min-width: 50vw;
}
  
.summary-text {
    font-size: 1.4rem;
    color: #4e4e4e;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
}

@media (max-width: 1200px) {
    .Summary {
        margin-top: 1rem;
        padding: 1rem 1rem;
    }

    .summary-text {
        font-size: 1.2rem;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .Summary {
        border: 1px solid #333;
        box-shadow: rgba(50, 50, 50, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    }
    
    .summary-text {
        color: #ebebeb;
    }
}