.Score {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.score-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border: 1px solid #aaa;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    border-radius: 10px;
    padding: .8rem;
    width: 100%;
}

.score-section h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    margin-top: -.2rem;
    color: #333;
}

.score-bold-text {
    font-weight: 900;
    font-size: 3.5rem;
    text-shadow: 0 0 0.1em #00000020;
    color: #5C86FF;
}

.average-entry {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.average-entry h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
}

.average-entry .average-score {
    font-size: 2.2rem;
    color: #5C86FF;
}

.average-pie-chart {
    margin-top: .7em;
    padding-right: .5em;
    width: 4em;
}

.score-button {
    color: #333;
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
    letter-spacing: .05em;
    background-color: transparent;
    margin-top: 0.3rem;
}

.score-button:hover {
    color: #5C86FF;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #FBC56C;
    text-underline-offset: 5px;
}

@media (max-width: 1000px) {
    .Score {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .score-section {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        text-align: center;
        padding: .5rem 1rem;
    }

    .score-section h3 {
        width: 90%;
        text-align: left;
        font-size: 1.6em;
    }

    .average-entry {
        width: 90%;
        flex-direction: row;
        font-size: 1.2em;
        text-align: left;
    }

    .average-entry .average-score {
        font-size: 1.5rem;
    }

    .Score-header {
        margin-bottom: 0rem;
    }

    .average-pie-chart {
        width: 3.5em;
    }

}

@media screen and (prefers-color-scheme: dark) {
    .Score {
        color: #fff;
    }

    .Score-header {
        color: #fff;
    }

    .score-section h3 {
        color: #fff;
    }

    .average-entry h1 {
        color: #fff;
    }

    .score-button {
        color: #fff;
        background-color: #222;
    }

    .score-button:hover {
        color: #fff;
    }
}