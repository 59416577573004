.OutputSentences {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #aaa; */
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset; */
    border-radius: 10px;
}

.OutputSentences h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 700;
}

.OutputSentences-bold-text {
    font-weight: 600;
    font-size: 1.3rem;
    text-shadow: 0 0 0.1em #00000020;
    color: #999;
    font-style: italic;
}

.OutputSentences-bold-score {
    font-weight: 600;
    font-size: 1.4rem;
    text-shadow: 0 0 0.1em #00000020;
    color: #5C86FF;
}

@media (max-width: 1000px) {
    .OutputSentences {
        margin-top: 1rem;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .OutputSentences h3 {
        color: #888;
    }
    
    .OutputSentences-bold-text {
        color: #ddd;
    }
    
    .OutputSentences-bold-score {
        color: #5C86FF;
    }
}