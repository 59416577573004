.Output {
    display: flex;
    flex-direction: column;
}

.Output-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.Output-row-left {
    width: 100%;
}

.Output-row-right {
    /* margin-left: -4rem; */
}

.Output-container {
    padding: 0rem 5rem;
    padding-bottom: 0rem;
    display: flex;
    flex-direction: column;
}

.Output-scroll {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.Output-notice p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    margin-top: -.1rem;
    /* margin-bottom: 0rem; */
}

.Output-notice-text-bold {
    font-weight: 700;
    color: #5c86ff;
}

.Output-scroll p {
    font-size: 3rem;
    font-weight: 500;
    color: #333;
    margin-top: -1rem;
    /* margin-bottom: -2rem; */
}

@media (max-width: 1000px) {
    .Output {
        flex-direction: column;
    }

    .Output-scroll {
        margin-bottom: 0rem;
    }
    
    .Output-container {
        padding: 0rem 1rem;
    }

    .Output-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;
    }
    
    .Output-row-left {
        width: 100%;
    }

}

@media (max-width: 1400px) {    
    .Output-row-right {
        margin-left: 2rem;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .Output-notice p {
        color: #ddd;
    }

    .Output-scroll p {
        color: #ddd;
    }
}