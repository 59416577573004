html {
  background-color: #fff;
}

.App-background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
}

.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10;
}

header {
  min-height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.App-header-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 1.8em;
}

.App-header-button:hover {
  transform: scale(1.04);
}

.App-main {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.App-footer {
  position: absolute;
  bottom: .1em;
  width: 100%;
  z-index: 100;
}


.App-footer-bottom {
  position: relative;
  margin-top: 3em;
}

@media (max-height: 800px) {
  .App-main {
    margin-top: 2em;
    gap: 2rem;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .App-main {
    gap: 2rem;
  }

  header {
    min-height: 10vh;
  }
}

@media screen and (prefers-color-scheme: dark) {
  html {
    background-color: #222;
  }

  .App-background {
    background-color: #222;
    opacity: 0.05;
    background-image:  linear-gradient(#5c86ff 1.4000000000000001px, transparent 1.4000000000000001px), linear-gradient(to right, #5c86ff 1.4000000000000001px, #222 1.4000000000000001px);
  }

  .App {
    background-color: #222;
  }
}