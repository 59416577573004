.Input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65vw;
    margin-bottom: 2rem;
}

.Input-button-container {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    margin-top: .5rem;
}

.Input-text {
    margin-bottom: 2em;
}

.input {
    font-size: 1.4rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 1rem;
}

.Input .input {
    width: 60vw;
    height: 40vh;
    padding: 1rem;
    border: 1px solid #bbb;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 3rem;
    background-color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    /* box-shadow: 0 0.4rem #cfc9c9; */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    border: 3px solid #cfc9c9;
    cursor: pointer;
    outline: none;
    text-align: left;
    resize: none;
}
    
.input-wrapper div.input:focus {
    outline-color: #5c86ff;
}

.input-word-count {
    font-size: 1.2rem;
    font-weight: 500;
    color: #555;
    margin-top: -3.5rem;
    text-align: right;
}
  
.placeholder {
    color: #4967c4;
    opacity: .8;
}
  
.Input button {
    --color-primary: #5c86ff;
    --color-secondary: #FBC56C;
    --background: hsl(230, 30%, 15%);
    --text: hsl(0, 0%, 100%);
    padding: 1.2em 2.8em;
    font-size: 1.3rem;
    letter-spacing: 1.5px;
    font-weight: 700;
    color: #000;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    border: none;
    color: #fff;
    background-color: var(--color-primary);
    margin-right: 1rem;
    margin-left: 1rem;
    /* background-image: linear-gradient(90deg, var(--color-primary), var(--color-secondary)); */
}
    
.Input button:hover {
    /* box-shadow: 0px 15px 20px #275EFEaf; */
    box-shadow: #275EFEaf 0px 4px 8px, #275EFE9f 0px 24px 26px -3px, #0000003f 0px -3px 0px inset;
    transform: translateY(-7px);
}
    
.Input button:active {
    transform: translateY(-1px);
}

.Input-button-yellow {
    background-color: #FBC56C;
}

.Input-button-yellow:hover {
    background-color: #FBC56C;
    box-shadow: 0px 15px 20px #be924d;
    color: #fff;
    transform: translateY(-7px);
}

@media (max-width: 1200px) {
    .Input {
        flex-direction: column;
    }
    
    .Input .input {
        width: 80vw;
        height: 30vh;
        padding: 1rem;
        border: 1px solid #bbb;
        border-radius: 10px;
        font-size: 1.1rem;
        font-weight: 500;
        color: #555;
        margin-bottom: 3rem;
        /* box-shadow: 0 0.4rem #cfc9c9; */
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
        border: 3px solid #cfc9c9;
        cursor: pointer;
        outline: none;
        text-align: left;
        resize: none;
    }

    .input-word-count {
        margin-top: -3.5rem;
        margin-left: -1rem;
    }

    .Input-button-container {
        display: flex;
        flex-direction: row;
        align-self: center;
        margin-top: 0rem;
    }
    
    .Input button {
        padding: 1.1em 2.5em;
        font-size: 1.2rem;
        letter-spacing: 2.5px;
        font-weight: 500;
        color: #000;
        background-color: #ddd;
        border: none;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        color: #fff;
        background-color: #5C86FF;
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .Input .input {
        background-color: #333;
        color: #fff;
        border: 1px solid #555;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
    }
    
    .Input button {
        background-color: #5C86FFcf;
        color: #fff;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    }

    .Input button:hover {
        background-color: #5C86FFcf;
        box-shadow: #5C86FF5f 0px 4px 8px, #5C86FF1f 0px 24px 26px -3px, #0000003f 0px -3px 0px inset;
        color: #fff;
        transform: translateY(-7px);
    }
}